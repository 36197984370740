import Link from "next/link";
import { useContext, useEffect, useState } from "react";
import { ROUTES } from "src/common/config/routes";
import { Counter } from "src/logos/Counter";
import { MenuContext } from "../../context/MenuContext";
import { LandingSection } from "../LandingSection";
import { HamburgerButton } from "../HamburgerButton";
import { NavMenuLink } from "../NavMenuLink";
import { Button } from "src/common/components/Button";
import { usePathname } from "next/navigation";
import { isValidToken } from "src/api/clients/loginClient";
import { getIsUserActivated } from "src/common/utils/get-is-user-activated";
import { Spinner } from "src/common/ui/Spinner";

export function Navbar() {
  const { isOpen, setIsOpen } = useContext(MenuContext);
  const pathname = usePathname();
  const isHome = pathname === "/";
  const hasValidToken = isValidToken();
  const [isSticky, setIsSticky] = useState(!isHome);
  const [isActivated, setIsActivated] = useState(false);
  const [isLoadingUser, setIsLoadingUser] = useState(true);

  useEffect(() => {
    const onScroll = () => {
      if (window.scrollY > 30) {
        setIsSticky(true);
      } else if (isHome) {
        setIsSticky(false);
      }
    };

    onScroll();

    window.addEventListener("scroll", onScroll);

    async function loadUserActivationStatus() {
      const [isAuthenticated, isActivated] = await getIsUserActivated();
      setIsActivated(isActivated);
      setIsLoadingUser(false);
    }

    loadUserActivationStatus();

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  });

  return (
    <>
      <div
        className={`left-1/2 w-full h-[60px] lg:h-[80px] lg:rounded-bl-[30px] lg:rounded-br-[30px] z-[1] fixed bg-[rgba(255,255,255,0.85)] backdrop-blur-xl -z-[1] lg:shadow-[0_4px_50px_0px_rgba(0,0,0,0.1)] top-0 -translate-x-1/2 transition-all ${
          isSticky || isOpen ? "opacity-1 !h-[60px]" : "opacity-0"
        }`}
      />
      <LandingSection
        as="nav"
        className={`sticky top-0 z-[2] transition-all h-[80px] ${
          isOpen ? "lg:!h-[80px]" : ""
        }`}
      >
        <div
          className={`flex items-center w-full justify-between transition-all h-[60px]  ${
            isSticky || isOpen ? "h-[60px]" : "lg:h-[80px]"
          } `}
        >
          <Link
            title="Homepage"
            href={ROUTES.HOME}
            className="outline-none focus-visible:ring-1.5 focus-visible:ring-black focus-visible:ring-offset-3"
          >
            <Counter
              className={`w-[120px] h-auto stroke-1 lg:text-accent transition-all ${
                isSticky || isOpen
                  ? "opacity-1 pointer-events-auto text-accent"
                  : "opacity-0 pointer-events-none"
              }`}
            />
          </Link>
          <div className="flex lg:hidden">
            <div className="-mr-8">
              <HamburgerButton
                isOpen={isOpen}
                className={`transition-all text-white ${
                  isSticky || isOpen ? "!text-accent" : ""
                }`}
                onClick={() => setIsOpen((prev) => !prev)}
                title="Open Menu"
              />
            </div>
          </div>
          <nav
            className={`shadow-[0_35px_35px_0px_rgba(0,0,0,0.1)] lg:shadow-none opacity-0 transition-all absolute w-full py-12 left-0 z-[5] pointer-events-none top-[60px] items-center flex flex-col bg-[rgba(255,255,255,0.85)] backdrop-blur-xl -z-[1] lg:pointer-events-all lg:bg-transparent lg:py-0 lg:backdrop-blur-none lg:flex lg:flex-row lg:w-auto lg:relative lg:top-auto lg:!pointer-events-auto lg:!opacity-[1] ${
              isOpen ? "!opacity-[1] pointer-events-auto" : ""
            }`}
          >
            <NavMenuLink to="/#how-it-works">How it works</NavMenuLink>
            <NavMenuLink to="/#support">Support</NavMenuLink>
            <NavMenuLink to="/#technology">Technology</NavMenuLink>
            <NavMenuLink to="/#contact">Contact</NavMenuLink>

            <div className="relative">
              <div
                className={`transition-all opacity-0 ${
                  isLoadingUser
                    ? "opacity-0 pointer-events-none"
                    : "opacity-100 pointer-events-auto"
                }`}
              >
                {isLoadingUser || (!isLoadingUser && !isActivated) ? (
                  <>
                    <Button
                      variant="primary"
                      size="sm"
                      className="mx-3.5 my-5 lg:my-0"
                      colorSchema="landing-filled"
                      as={Link}
                      href={ROUTES.EMPLOYEE.LOGIN}
                    >
                      Log in
                    </Button>
                    <Button
                      variant="transparent"
                      size="sm"
                      colorSchema="landing"
                      as={Link}
                      href={ROUTES.EMPLOYEE.ONBOARDING.ACTIVATE_YOUR_ACCOUNT}
                    >
                      Activate
                    </Button>
                  </>
                ) : (
                  <Button
                    variant="primary"
                    size="sm"
                    className="mx-3.5 my-5 lg:my-0"
                    colorSchema="landing-filled"
                    as={Link}
                    href={ROUTES.EMPLOYEE.PORTAL.ROOT}
                  >
                    Your Dashboard
                  </Button>
                )}
              </div>
              <Spinner
                className={`absolute left-1/2 w-[40px] h-[40px] top-0 transition-all pointer-events-none ${
                  isLoadingUser ? "opacity-1" : "opacity-0"
                }`}
              />
            </div>
          </nav>
        </div>
      </LandingSection>
    </>
  );
}
